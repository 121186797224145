<template>
  <!-- eslint-disable -->
  <img src="../assets/photos/logo.png" alt="onco-minds photo" class="logo"/>
  <h1 v-if="!success" class="p-mt-6">Reset Your Password</h1>
  <div v-if="!success" class="p-d-flex p-ai-center p-jc-center">
    <InputText
      type="text"
      style="width:300px"
      class="p-mt-4 p-mr-2"
      v-model="password"
      placeholder="Enter your New Password"
    />
    <Button
      label="Reset Password"
      class="p-mt-4 p-ml-2"
      @click="resetPassword"
    />
  </div>
  <div v-else>
    <h2>Password Has been Reset</h2>
    <Button label="Go to Login Page" class="p-mt-2" @click="gotoLogin" />
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { auth } from "../firebase/config";
import { useRoute, useRouter } from "vue-router";
import { confirmPasswordReset } from '@firebase/auth';
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const password = ref(null);
    const success = ref(false);
    const code = route.query.oobCode;
    const resetPassword = async () => {
      try {
        // await auth.confirmPasswordReset(code, password.value);
        confirmPasswordReset(auth, code, password.value)
        success.value = true;
        password.value = null;
      } catch (error) {
        console.log(error);
      }
    };
    const gotoLogin = () => {
      router.push({ name: "main" });
    };
    return { password, resetPassword, success, gotoLogin };
  },
};
</script>

<style scoped>
.logo {
    width: 400px;
}
</style>